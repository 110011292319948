import { Route, useRouteMatch } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import classes from "./InformationsContent.module.css";

import MotionCard from "../UI/Card";
import InformationList from "./InformationList";
import SingleInformation from "./SingleInformation";
import { informations } from "../../constants/informationsData";

const InformationsContent = () => {
  const { path, url } = useRouteMatch();

  return (
    <div className={classes.wrapper}>
      <MotionCard className={classes.infoListCard}>
        <InformationList informations={informations} />
      </MotionCard>
      <Route path={`${path}/:infoId`}>
        <AnimatePresence>
          <MotionCard
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            key={url}
            className={classes.singleInfoCard}
          >
            <SingleInformation informations={informations} />
          </MotionCard>
        </AnimatePresence>
      </Route>
    </div>
  );
};

export default InformationsContent;
