import { routes } from "../routes/routes";

export const navLinks = [
  { id: 1, name: "Почетна", url: `/${routes.home}` },
  { id: 2, name: "О нама", url: `/${routes.aboutUs}` },
  { id: 3, name: "Обавештења", url: `/${routes.announcements}` },
  { id: 4, name: "Скупштина", url: `/${routes.assembly}` },
  { id: 5, name: "Документа", url: `/${routes.documents}` },
  { id: 6, name: "Корисне информације", url: `/${routes.usefulInfo}` },
  { id: 7, name: "Галерија", url: `/${routes.gallery}` },
];
