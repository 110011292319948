import MotionCard from "../components/UI/Card";

import classes from "./NotFound.module.css";

const NotFound = () => {
  return (
    <MotionCard
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}
      className={classes.card}
    >
      <div className={classes["text-wrapper"]}>
        <h2>Stranica kojoj ste pokušali da pristupite ne postoji</h2>
        <p>Molimo proverite da li ste ispravno uneli link u pretraživač</p>
      </div>
    </MotionCard>
  );
};

export default NotFound;
