import { NavLink } from "react-router-dom";

import classes from "./DesktopNavBar.module.css";
import { navLinks } from "../../constants/navLinks";

const DesktopNavBar = (props) => {
  return (
    <nav className={classes["main-navigation"]}>
      <button
        type="button"
        title="Navigacija"
        className={classes.toggle}
        onClick={props.onShowSidebar}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
      <ul>
        {navLinks.map((link) => (
          <li key={link.id}>
            <NavLink activeClassName={classes.active} to={link.url}>
              {link.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default DesktopNavBar;
