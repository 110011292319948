import { Link } from "react-router-dom";
import { assemblyList } from "../../constants/assemblyList";
import classes from "./AssemblyList.module.css";

const AssemblyList = () => {
  const linkList = assemblyList.map((link) => (
    <li key={link.id} className={classes["list-item"]}>
      <Link to={link.document} target="_blank" rel="noopener noreferrer">
        {link.name}
      </Link>
    </li>
  ));

  return <ul className={classes.ul}>{linkList}</ul>;
};

export default AssemblyList;
