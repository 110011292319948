import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import classes from "./SingleInformation.module.css";

const SingleInformation = (props) => {
  const { informations } = props;
  const params = useParams();

  const [singleInfoTitleContent, setSingleInfoTitleContent] = useState(null);
  const [singleInfoTextContent, setSingleInfoTextContent] = useState(null);

  useEffect(() => {
    const singleInfo = informations?.find(
      (info) => info.id === Number(params.infoId)
    );

    setSingleInfoTitleContent(singleInfo.title);

    const singleInfoText = singleInfo.text;
    const formattedInfoText = singleInfoText
      .split("<br>")
      .map((str) => <p key={str}>{str}</p>);
    setSingleInfoTextContent(formattedInfoText);
  }, [informations, params.infoId]);

  return (
    <article className={classes.article}>
      <h1>{singleInfoTitleContent}</h1>
      <div>{singleInfoTextContent}</div>
    </article>
  );
};

export default SingleInformation;
