import classes from "./DocumentsContent.module.css";

import MotionCard from "../UI/Card";

const DocumentsContent = () => {
  return (
    <MotionCard
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}
      className={classes.card}
    >
      <div>
        <h2>Stranica je u pripremi...</h2>
      </div>
    </MotionCard>
  );
};

export default DocumentsContent;
