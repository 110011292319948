import Thumb1 from "../../assets/Thumb1.jpg";
import Thumb2 from "../../assets/Thumb2.jpg";
import Thumb3 from "../../assets/Thumb3.jpg";
import Thumb4 from "../../assets/Thumb4.jpg";
import Thumb5 from "../../assets/Thumb5.jpg";
import Thumb6 from "../../assets/Thumb6.jpg";
import Blok1 from "../../assets/Blok1.jpg";
import Blok2 from "../../assets/Blok2.jpg";
import Blok3 from "../../assets/Blok3.jpg";
import Blok4 from "../../assets/Blok4.jpg";
import Blok5 from "../../assets/Blok5.jpg";
import Blok6 from "../../assets/Blok6.jpg";

export const images = [
  { thumb: Thumb1, img: Blok1, width: "4032" },
  { thumb: Thumb2, img: Blok2, width: "4032" },
  { thumb: Thumb3, img: Blok3, width: "4032" },
  { thumb: Thumb4, img: Blok4, width: "4032" },
  { thumb: Thumb5, img: Blok5, width: "4032" },
  { thumb: Thumb6, img: Blok6, width: "4032" },
];
